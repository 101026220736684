import { createGlobalStyle } from "styled-components"

import Roboto from "./fonts/Roboto-Regular.ttf"

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Roboto';
        src: url(${Roboto}) format("truetype");
    }
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    body {
        background: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.black};
        text-rendering: optimizeLegibility;
        font-family: 'Roboto', sans-serif;
    }
    /* remove chrome input focus */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    input:hover, 
    input:focus,
    textarea:hover,
    textarea:focus {
        background-color: #161616 !important;
        color: white !important;
        border: none !important;
        outline: none !important;
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }
  `
export default GlobalStyles
