import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"
import { GlobalStyles, Theme } from "../../styles"

const Layout = ({ children }) => (
  <ThemeProvider theme={Theme}>
    <Normalize />
    <GlobalStyles />
    <main>{children}</main>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
